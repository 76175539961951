<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showDetail"
        max-width="1100px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
            <v-spacer> </v-spacer>
            <v-checkbox
              label="Nouveau Produit"
              v-model="newproduct"
              dense
              @change="newproduct_click"
              v-if="dem_type != 3"
            ></v-checkbox>

            <v-spacer> </v-spacer>
            <v-radio-group
              v-if="!newproduct && dem_type != 3"
              v-model="service"
              row
              mandatory
              dense
            >
              <v-radio label="Produits" :value="0"></v-radio>
              <v-radio label="Service" :value="1"></v-radio>
            </v-radio-group>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12" sm="6" md="8" v-if="newproduct">
                  <v-text-field
                    autocomplete="off"
                    ref="label"
                    dense
                    v-model="editedItem.label"
                    label="Produit"
                    :rules="[(v) => !!v || 'Produit obligatoire']"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="8"
                  v-if="!newproduct && allproduct && dem_type <= 2"
                >
                  <cursorselect
                    :Qsearch="products_cursor"
                    :Qresp="'products_cursor'"
                    :value="produit_id"
                    :text_fields="['code', 'label']"
                    :variableadd="variableadd"
                    @change="produit_change"
                    :label="'Produit'"
                    :key="cs"
                  >
                  </cursorselect>
                </v-col>
                <v-col
                  cols="12"
                  sm="8"
                  md="8"
                  v-if="!newproduct && dem_type == 2 && !allproduct"
                >
                  <v-autocomplete
                    v-model="editedItem.produit_id"
                    :item-value="'id'"
                    :items="products_list"
                    :filter="customFilter"
                    label="Produit"
                    :rules="[(v) => !!v || 'Produit obligatoire']"
                    @change="produit_change"
                    dense
                  >
                    <template v-slot:selection="{ item }">
                      <!-- HTML that describe how select should render selected items -->
                      {{ (item.code ? item.code + "-" : "") + item.label }}
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="
                            (item.code ? item.code + '-' : '') + item.label
                          "
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="1"
                  v-if="!newproduct && spec && dem_type == 2"
                >
                  <v-checkbox
                    label="Tous"
                    v-model="allproduct"
                    dense
                    @change="cs++"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="12" v-if="dem_type == 3">
                  <v-autocomplete
                    v-model="editedItem.produit_id"
                    item-text="product_name"
                    :item-value="'produit_id'"
                    :items="products"
                    :filter="customFilter"
                    label="Produit"
                    :rules="[(v) => !!v || 'Produit obligatoire']"
                    dense
                    @change="product_change"
                  >
                    <template v-slot:selection="{ item }">
                      <!-- HTML that describe how select should render selected items -->
                      {{
                        (item.product_code ? item.product_code + "-" : "") +
                        item.product_name
                      }}
                    </template>
                    <template slot="item" slot-scope="data">
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.product_name"
                        ></v-list-item-title>
                        <v-list-item-title
                          v-html="'Code : ' + data.item.product_code"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="12" v-if="dem_type == 3">
                  <v-autocomplete
                    v-model="editedItem.article_id"
                    item-text="article_name"
                    :item-value="'article_id'"
                    :items="articles"
                    label="Article"
                    :rules="[(v) => !!v || 'Article obligatoire']"
                    dense
                    :loading="loading"
                    @change="article_change"
                  >
                    <template v-slot:selection="{ item }">
                      <!-- HTML that describe how select should render selected items -->
                      {{
                        (item.code ? item.code + "-" : "") + item.article_name
                      }}
                    </template>
                    <template slot="item" slot-scope="data">
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.article_name"
                        ></v-list-item-title>
                        <v-list-item-title
                          v-html="'Code : ' + data.item.code"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="
                            'Ref. : ' +
                            (data.item.ref_ext ? data.item.ref_ext : '')
                          "
                        ></v-list-item-subtitle>
                        <v-list-item-subtitle
                          v-html="
                            'Fournisseur : ' +
                            (data.item.tier_name ? data.item.tier_name : '')
                          "
                        ></v-list-item-subtitle>
                        <v-list-item-subtitle
                          v-html="
                            'Quantite : ' +
                            (data.item.stock_cmpt ? data.item.stock_cmpt : 0) +
                            (data.item.unit ? ' ' + data.item.unit : '')
                          "
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="1">
                  <v-text-field
                    autocomplete="off"
                    ref="qte"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model="editedItem.qte"
                    :readonly="readonly"
                    :label="
                      editedItem.unit_name ? editedItem.unit_name : 'Quantité'
                    "
                    :rules="[
                      (v) =>
                        !!v ||
                        (editedItem.unit_name
                          ? editedItem.unit_name
                          : 'Quantité') + ' obligatoire',
                      (v) => !v || v > 0 || 'Valeur incorrecte',
                      (v) =>
                        parseFloat(v) <= parseFloat(qte_max) ||
                        dem_type != 3 ||
                        'Stock Max : ' + qte_max,
                    ]"
                    @keydown.enter="save"
                    hide-spin-buttons
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2" v-if="newproduct">
                  <v-select
                    ref="unite"
                    :items="units_list"
                    v-model="editedItem.fk_unit"
                    item-text="name"
                    item-value="id"
                    label="Unité de Mesure"
                    :readonly="readonly"
                    :rules="[(v) => !!v || 'Unité de Mesure obligatoire']"
                    dense
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="11" md="11">
                  <v-text-field
                    autocomplete="off"
                    ref="description"
                    dense
                    v-model="editedItem.description"
                    label="Description"
                    :readonly="readonly"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-left"
                  v-if="bag && dem_type == 2"
                >
                  Fichier Source :
                  <downloadcomp
                    :item="editedItem"
                    :field="'url'"
                    :can_upload="!readonly"
                    :can_preview="true"
                    :can_download="true"
                    :can_delete="!readonly"
                    :update_query="update_query"
                    :var_name="'demandedetail'"
                    @file_uploaded="file_uploaded"
                    @file_deleted="file_deleted"
                    :top="0"
                    :key="kdc"
                    :upload_type="'u'"
                  >
                  </downloadcomp>
                </v-col>
                <v-col
                  cols="12"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-left"
                  v-if="bag && dem_type == 2"
                >
                  Aperçu :
                  <downloadcomp
                    :item="editedItem"
                    :field="'preview_file'"
                    :can_upload="!readonly"
                    :can_preview="true"
                    :can_download="true"
                    :can_delete="!readonly"
                    :update_query="update_query"
                    :var_name="'demandedetail'"
                    @file_uploaded="file_uploaded"
                    @file_deleted="file_deleted"
                    :top="0"
                    :key="kdc"
                    :upload_type="'f'"
                  >
                  </downloadcomp>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-checkbox
                    :label="'Necessite ' + spec_tech"
                    v-model="editedItem.spec_need"
                    v-if="dem_type == 2 && spec_tech"
                  ></v-checkbox>
                </v-col>
                <!-- <v-col cols="12" sm="1" md="10">
                  <v-text-field
                    autocomplete="off"
                    ref="comment"
                    dense
                    v-model="editedItem.comment"
                    label="Commentaire"
                    :readonly="readonly"
                  ></v-text-field>
                </v-col> -->
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-container fill-height>
              <v-layout row justify-center align-center v-if="progress">
                <v-progress-circular
                  indeterminate
                  :size="30"
                  :width="7"
                  color="purple"
                ></v-progress-circular>
              </v-layout>
            </v-container>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" @click="save" v-if="!readonly">
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" @click.stop="close"> Fermer </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import CREATE_DEMANDE_DETAIL from "../graphql/Demande/CREATE_DEMANDE_DETAIL.gql";
import UPDATE_DEMANDE_DETAIL from "../graphql/Demande/UPDATE_DEMANDE_DETAIL.gql";
import PRODUCTS_CURSOR from "../graphql/Commande/PRODUCTS_CURSOR.gql";

import { spec, spec_tech, bag } from "print/data.js";

export default {
  components: {
    cursorselect: () => import("./CursorSelect.vue"),
    downloadcomp: () => import("../components/DownloadComp.vue"),
  },
  name: "demdetailform",

  props: {
    item: Object,
    items: Array,
    readonly: Boolean,
    showForm: Boolean,
    dem_type: Number,
    tier_products: Array,
    units: Array,
    products: Array,
    demande: Object,
  },

  data: () => ({
    chosenFiles: [],
    service: 0,
    kdc: 1489,
    duedate: null,
    newproduct: false,
    allproduct: true,
    progress: false,
    valid: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    editedItem: {},
    combovalue: "",
    template: 0,
    attributs: [],
    units_list: [],
    unfilter: false,
    pageInfo: null,
    label: null,
    produit_id: null,
    cs: 0,
    label_ext: "",
    ref_ext: "",
    loading: false,
    isSelecting: false,
    chosenFile: null,
    uploading: false,
    articles: [],
    product: {},
    qte_max: 0,
  }),

  computed: {
    bag() {
      return bag;
    },
    update_query() {
      return UPDATE_DEMANDE_DETAIL;
    },
    spec_tech() {
      return spec_tech;
    },
    variableadd() {
      let w = {
        Kind: this.dem_type == 1 || this.dem_type == 3 ? 2 : 3,
        Type: this.$store.state.products_types.map((elm) => elm.id),
        statut_id: [2],
      };
      return w;
    },
    products_cursor() {
      return PRODUCTS_CURSOR;
    },
    spec() {
      return spec;
    },
    showDetail() {
      return this.showForm;
    },

    formTitle() {
      if (this.item)
        return this.item.id < 0
          ? "Nouvelle Ligne  "
          : "Ligne N° ".concat(parseInt(this.item.index) + 1);
      else return "";
    },
    products_list() {
      let list = [];
      let l = this.dem_type == 2 ? this.tier_products : [];

      l = l.filter(
        (elm) =>
          !this.items.map((elm2) => elm2.label).includes(elm.label) ||
          elm.label == this.editedItem.label
      );
      if (l)
        list = l.filter(
          (elm) => elm.service == this.service || elm.service == null
        );
      return list;
    },
  },
  watch: {},

  created() {},
  async mounted() {
    this.newproduct = this.dem_type != 3;
    this.allproduct = !this.spec || this.dem_type == 1 || this.dem_type == 3;
    if (this.item) {
      this.editedItem = Object.assign({}, this.item);
      this.kdc++;
      if (this.editedItem.id > 0) {
        this.produit_id = this.editedItem.produit_id;
        this.cs++;
        if (this.dem_type <= 2) {
          if (!this.editedItem.produit_id) {
            this.newproduct = true;
            this.newproduct_click();
          }
          this.produit_change(this.editedItem.produit_id);
        } else {
          this.units_list = this.units;
          this.product_change();
          this.article_change();
        }
      } else this.units_list = this.units;
    } else this.units_list = this.units;
  },

  methods: {
    async product_change() {
      let l = this.products.filter(
        (elm) =>
          elm.produit_id == this.editedItem.produit_id &&
          (!this.items.map((elm) => elm.article_id).includes(elm.id) ||
            elm.id == this.editedItem.article_id) &&
          elm.depot_id != this.demande.depot_id
      );
      this.articles = [];
      l.forEach((element) => {
        let i = this.articles.findIndex(
          (elm) => elm.article_id == element.article_id
        );
        if (i < 0) this.articles.push(element);
        else {
          this.articles[i].stock_cmpt =
            this.articles[i].stock_cmpt + element.stock_cmpt;
        }
      });
      if (this.articles.length == 1) {
        this.editedItem.article_id = this.articles[0].article_id;
        this.article_change();
      }
    },
    async article_change() {
      let i = this.articles.findIndex(
        (elm) => elm.article_id == this.editedItem.article_id
      );
      if (i >= 0) {
        this.stock_boxs = this.articles[i].stock_boxs;

        this.kflist++;
        this.editedItem.pmp = this.articles[i].pmp;
        this.editedItem.fk_unit = this.articles[i].fk_unit;
        this.editedItem.ppattributs = this.articles[i].ppattributs;
        this.editedItem.unit_name = this.articles[i].unit_name;
        this.editedItem.unit = this.articles[i].unit;
        this.editedItem.is_expire = this.articles[i].is_expire;
        this.editedItem.product_name = this.articles[i].product_name;
        this.editedItem.bylist = this.articles[i].bylist;

        this.precision = this.articles[i].decimal;
        let l = this.articles.filter(
          (elm) =>
            elm.article_id == this.editedItem.article_id &&
            elm.depot_id != this.demande.depot_id
        );
        let qte_max = 0;
        l.forEach((element) => {
          qte_max = qte_max + (element.stock_cmpt ? element.stock_cmpt : 0); //element.stock_cmpt;
        });
        this.qte_max = qte_max;
      }
    },
    newproduct_click() {
      if (this.newproduct) {
        this.editedItem.produit_id = null;
        this.units_list = this.units;
      } else {
        this.editedItem.label = null;
        this.editedItem.pu = null;
        this.editedItem.qte = null;
        this.editedItem.fk_unit = null;
        this.units_list = [];
        this.$refs.form.resetValidation();
      }
    },
    get_units() {
      let k = this.units.findIndex(
        (elm) => elm.id == this.editedItem.fk_unit_product
      );
      if (k >= 0) {
        let c = this.units[k].category_id;
        this.units_list = this.units.filter((elm) => elm.category_id == c);
      }
      k = this.units_list.findIndex(
        (elm) => elm.id == this.editedItem.fk_unit_buy
      );
      if (k == -1) {
        let i = this.units.findIndex(
          (elm) => elm.id == this.editedItem.fk_unit_buy
        );
        if (i >= 0)
          this.units_list.push({
            id: this.units[i].id,
            name: this.units[i].name,
          });
      }
    },

    produit_change(item, l) {
      let i;
      let list = this.products_list;
      if (l) list = l;
      this.produit_id = item;
      if (this.produit_id) {
        i = list.findIndex((elm) => elm.id == this.produit_id);

        if (i >= 0) {
          this.editedItem.produit_id = list[i].id;
          let produit = list[i];
          this.editedItem.fk_unit_buy = produit.fk_unit_buy;
          this.editedItem.fk_unit_product = produit.fk_unit;

          //unite de mesure
          if (produit.fk_unit && this.editedItem.id < 0)
            this.editedItem.fk_unit = produit.fk_unit;

          this.get_units();

          this.editedItem.unit_name = produit.unit_name;

          this.$refs.qte.focus();
        }
      } else this.units_list = this.units.filter((elm) => elm.uom_type == "m");
    },

    customFilter(item, queryText) {
      let textlist = [];
      ["ref", "code", "label", "product_code", "product_name"].forEach(
        (element) => {
          const text1 = item[element] ? item[element].toLowerCase() : "";
          textlist.push(text1);
        }
      );

      const searchText = queryText.toLowerCase();
      const index = textlist.findIndex((element) => {
        if (element.includes(searchText)) {
          return true;
        }
      });
      return index !== -1;
    },

    async requette(query, v) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.loading = false;
        });
      return r;
    },
    file_uploaded(data, field) {
      this.editedItem[field] = data;

      this.kdc++;
    },
    file_deleted(field) {
      this.editedItem[field] = null;
      this.kdc++;
    },
    close() {
      this.$emit("close");
    },
    async maj(query, v) {
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then(() => {
          this.$store.dispatch("Changed", true);
          this.progress = false;
          this.close();
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
    },
    async save() {
      if (this.$refs.form.validate()) {
        let v;
        let ok = true;

        if (ok) {
          if (this.editedItem.id > 0) {
            v = {
              demandedetail: {
                id: this.editedItem.id,
                qte: parseFloat(this.editedItem.qte),
                produit_id: this.editedItem.produit_id,
                article_id: this.editedItem.article_id,
                label: this.editedItem.label,
                fk_unit: this.editedItem.fk_unit,
                description: this.editedItem.description,
                spec_need: this.editedItem.spec_need ? 1 : 0,
                comment: this.editedItem.comment,
                write_uid: this.$store.state.me.id,
              },
            };

            await this.maj(UPDATE_DEMANDE_DETAIL, v);
          } else {
            v = {
              demandedetail: {
                qte: parseFloat(this.editedItem.qte),
                description: this.editedItem.description,
                produit_id: this.editedItem.produit_id,
                article_id: this.editedItem.article_id,
                label: this.editedItem.label,
                fk_unit: this.editedItem.fk_unit,
                dem_id: this.item.dem_id,
                spec_need: this.editedItem.spec_need ? 1 : 0,
                url: this.editedItem.url,
                preview_file: this.editedItem.preview_file,
                comment: this.editedItem.comment,
                create_uid: this.$store.state.me.id,
              },
            };
            v.demandedetail.dem_id = this.item.dem_id;
            await this.maj(CREATE_DEMANDE_DETAIL, v);
          }
        }
      }
    },
  },
};
</script>
